import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import TokenLogin from 'components/TokenLogin'

import configureStore from '../agency/redux/configureStore'

const store = configureStore()

document.addEventListener('DOMContentLoaded', () => {
  const div = document.createElement('div')
  div.setAttribute('id', 'js-jwt-login')
  const domNode = document.body.appendChild(div)

  const root = ReactDOM.createRoot(domNode)

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <TokenLogin />
      </Provider>
    </React.StrictMode>,
  )
})
