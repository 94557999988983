import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { reducers } from './index'

const rootReducer = history => combineReducers({
  ...reducers,
  form: formReducer,
  router: connectRouter(history),
})

export default rootReducer
