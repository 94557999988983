import { useEffect } from 'react'
import queryString from 'query-string'

import { digObject } from 'functions/utilities'

import useThunkDispatch from 'hooks/useThunkDispatch'

import * as userActions from 'agency/redux/modules/user'

const loginWithToken = (authToken) => {
  const url = `/agency/users/token_login/${authToken}?token_type=jwt`
  window.location.href = url
}

const invalidateToken = () => {
  localStorage.removeItem('Realhub-JWT')
}

const validateToken = (authToken, dispatch) => {
  const { validateAuthToken } = userActions
  return dispatch(validateAuthToken({ auth_token: authToken, token_type: 'jwt' }))
}

const findAndValidateToken = (dispatch) => {
  const token = localStorage.getItem('Realhub-JWT')
  if (token){
    // Check that the token is valid on server
    validateToken(token, dispatch).then(({ success, result }) => {
      if (!success) return

      const valid = digObject(result, 'data.valid', false)

      // If not valid, clear it from the browser
      if (!valid){
        invalidateToken()
        return
      }

      // Valid - Login!
      loginWithToken(token)
    })
  }
}

const clearQueryString = (query) => {
  const updatedQuery = { ...query }

  if (updatedQuery.persist_login){
    delete updatedQuery.persist_login
  }

  const stringified = queryString.stringify(updatedQuery)

  if (window.history.pushState){
    const { protocol, host, pathname } = window.location
    const newurl = `${protocol}//${host}${pathname}${stringified ? `?${stringified}` : ''}`
    window.history.replaceState({ path: newurl }, '', newurl)
    return
  }

  window.location.search = stringified
}

const storeAuthToken = (token) => {
  localStorage.setItem('Realhub-JWT', token)
}

const persistLogin = (user, dispatch) => {
  const { loadUserAuthToken } = userActions

  const query = queryString.parse(window.location.search)

  if (query.persist_login){
    dispatch(loadUserAuthToken(user, { token_type: query.persist_login })).then(({ success, result }) => {
      if (!success) return

      const token = digObject(result, 'data.token')
      if (token){
        storeAuthToken(token)
        clearQueryString(query)
      }
    })
  }
}

function usePersistedLogin(initUser, options = {}){
  const { autoLogin } = options || {}
  const user = initUser || {}

  const dispatch = useThunkDispatch()

  // Store Token
  useEffect(() => {
    if (user.id){
      persistLogin(user, dispatch)
    }
  }, [user.id])

  // Use Stored Token to Login
  useEffect(() => {
    if (autoLogin){
      findAndValidateToken(dispatch)
    }
  }, [autoLogin])

  return {
    user,
  }
}

export default usePersistedLogin
