import React from 'react'

import usePersistedLogin from 'agency/hooks/usePersistedLogin'

const TokenLogin = () => {
  usePersistedLogin(null, { autoLogin: true })

  return null
}

export default TokenLogin
