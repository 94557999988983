/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createHashHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import lastModifiedMiddleware from 'redux-modules/middleware/lastModified'

import rootReducer from './modules/reducers'

const history = createHashHistory()
const router = routerMiddleware(history)
const enhancers = [applyMiddleware(thunk, router, lastModifiedMiddleware)]

const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window

let composeEnhancers = compose

if (process.env.NODE_ENV !== 'production' && __REDUX_DEVTOOLS_EXTENSION_COMPOSE__){
  composeEnhancers = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
}

export default function configureStore(initialState){
  const store = createStore(rootReducer(history), initialState, composeEnhancers(...enhancers))

  if (module.hot){
    // Enable webpack hot module replacement for reducers
    module.hot.accept(
      './modules/reducers',
      () => store.replaceReducer(rootReducer(history)),
    )
  }

  return store
}
